import React from "react";
import { Container, Divider, Grid } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import FeatureList from "../../components/feature-list/feature-list";
import colors from "../../components/colors";
import Quote from "../../components/quote/quote";
import BodyContent from "../../components/body-content/body-content";

const Page = () => {
  return (
    <LayoutInner microsite>
      <SEO
        title="Book a free music consultation"
        keywords={[`rocksteady`, `free`, `consultation`]}
      />

      <Container>
        <SecondaryNavBar
          product="primary"
          title="Book a free music consultation"
          titlePath="/book-consultation/"
          titleActive={true}
          links={[]}
          cta="Yes, I’d  like to book a free music consultation​"
          ctaMobile="Book consultation"
          ctaLink="https://form.jotform.com/RocksteadyMusic/freeresources"
        />

        <HeadingOverImage
          text="Free music consultation for your school"
          img="/images/book-consultation/bg.png"
          siteSection="primary"
        />

        <Divider hidden />

        <BodyContent>
          <p>
            It can be difficult to deliver the music curriculum in primary
            school if you are not lucky enough to have your own Music
            specialist. If you would like some help and advice about how Music
            can support Wellbeing, inclusion and progression at your school, let's
            chat. We'll tell you how Rocksteady has helped thousands of Primary
            Schools across the UK and do our best to answer any questions you
            have about music in your school. ​
          </p>
          <p>
            To book a call at a time that suits you, simply click on the button
            below and before you know it, music could be helping support:​
          </p>
        </BodyContent>

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "Confidence",
              text:
                "Music allows children to express themselves and helps build their self-esteem, which can benefit all areas of school life.​",
            },
            {
              title: "Attainment",
              text:
                "Music lessons improve children’s memory, numeracy and literacy, boosting their achievement at school.​",
            },
            {
              title: "Wellbeing",
              text:
                "Playing an instrument is proven to reduce anxiety and promote emotional wellbeing, which improves overall happiness inside and outside school.​",
            },
            {
              title: "​Behaviour",
              text:
                "Learning to play an instrument develops the part of the brain that deals with behaviour regulation and motor skills, improving children’s focus and progress in class.",
            },
            {
              title: "Resilience​",
              text:
                "Children learn to overcome challenges together in a safe environment, creating positive learning behaviours to help them progress at school.​",
            },
            {
              title: "Inclusive",
              text:
                "Playing music together is a great leveller and can help support children with special educational needs or those with English as an additional language.​",
            },
          ]}
        />

        <BodyContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="https://form.jotform.com/RocksteadyMusic/freeresources"
              className="ui button"
              style={{
                backgroundColor: colors["primary"].cta,
                color: colors["primary"].ctaText,
                width: 500,
                borderRadius: 30,
                fontSize: "2em",
                lineHeight: "initial",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to book your
              <br />
              FREE music consultation
            </a>
          </div>
          <Divider hidden />
          <Grid columns={1}>
            <Grid.Column>
              <Quote
                siteSection="primary"
                quote="I work with vulnerable children within my school, who find education a challenge and lack self-worth. I have noticed that since attending the Rocksteady classes they have become focused, behaviour in class is more positive and it has enabled these children to express themselves.​"
                quoteName="Pastoral Support Teacher, Gloucestershire​​"
                reducedMargin
              />
            </Grid.Column>
          </Grid>
        </BodyContent>
      </Container>
    </LayoutInner>
  );
};

export default Page;
